// HistoricoPage.tsx

import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Row, Col, Skeleton, message, Empty, Pagination } from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { ActivityContents } from './style';
import AppContext from '../../../../context';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ProjectPagination } from '../../../empreendimentos/style';
import antdTableLocale from '../../../../config/locale/antd-table';

type SmartChange = {
  key: string;
  oldValue?: any;
  newValue?: any;
};

type Corretor = {
  id: string;
  nome: string;
  email: string;
  foto?: string;
};

type ClienteRazao = {
  id: string;
  nome: string;
};

type ClienteEtapa = {
  id: string;
  title: string;
};

type MetaPageForm = {
  id: string;
  name: string;
};

type Imovel = {
  db_id: string;
  codigo: string;
};

type SmartUpdate = {
  auth: null;
  authType: null;
  changed_values: Array<SmartChange>;
  corretor_id?: string;
  eventType: string;
  origem: null | string;
  resourceType: null;
  last_update_time: Date;
  updates: string; // JSON string com { changed_values: [...] }
  corretor?: Corretor;
  cliente?: Cliente;
  imovel_origem?: Imovel;
  etapa_funil?: ClienteEtapa;
  last_etapa_funil?: ClienteEtapa;
  formulario?: MetaPageForm;
  razao?: ClienteRazao;
};

type Cliente = {
  // Defina aqui os campos necessários do cliente
};

type LogDTO = {
  // Defina aqui os campos necessários do log DTO
};

type PaginacaoLog = {
  nodes: LogDTO[];
  total: number;
};

const dateFields = ['data_entry_funil', 'registered_at', 'data_nasc', 'reregistered_at', 'last_update_time', 'checklist_expiration_date'];

const referenceFieldsMap: { [key: string]: {
  entityPath: string[];
  displayField: string;
  route: string;
  imageField?: string;
} } = {
  corretor_responsavel_id: {
    entityPath: ['cliente', 'corretor_responsavel'],
    displayField: 'nome',
    route: '/admin/corretores/',
    imageField: 'foto',
  },
  cliente_id: {
    entityPath: ['cliente'],
    displayField: 'nome',
    route: '/admin/clientes/',
  },
  razao_id: {
    entityPath: ['cliente', 'razao'],
    displayField: 'nome',
    route: '#',
  },
  proprietario_id: {
    entityPath: ['cliente', 'imovel_origem'],
    displayField: 'codigo', // Exemplo: código do imóvel
    route: '#',
  },
  etapa_funil_id: {
    entityPath: ['cliente', 'etapa_funil'],
    displayField: 'title',
    route: '#',
  },
  last_etapa_funil_id: {
    entityPath: ['cliente', 'last_etapa_funil'],
    displayField: 'title',
    route: '#',
  },
  formulario_id: {
    entityPath: ['cliente', 'formulario'],
    displayField: 'name',
    route: '#',
  },
  imovel_origem_id: {
    entityPath: ['cliente', 'imovel_origem'],
    displayField: 'codigo',
    route: '#',
  },
  edited_by_id: {
    entityPath: ['cliente', 'corretor'],
    displayField: 'nome',
    route: '#',
    imageField: 'foto',
  },
  corretor_id: {
    entityPath: ['cliente', 'corretor'],
    displayField: 'nome',
    route: '/admin/corretores/',
    imageField: 'foto',
  },
  // Adicione outros campos de referência conforme necessário
};

const HistoricoPage = ({ cliente_id }: { cliente_id: string }) => {
  const context = useContext(AppContext);

  const [historico, setHistorico] = useState<SmartUpdate[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const fetchData = async (cliente_id: string) => {
    setLoading(true);
    try {
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        limit: pageSize.toString(),
        startAt: ((current - 1) * pageSize).toString(),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/logs/clientes/${cliente_id}?${params.toString()}`, {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar o histórico do cliente.');
      }
      const data = await response.json();
      setHistorico(data.nodes);
      setTotal(data.total);
    } catch (error) {
      console.error(error);
      message.error('Algo deu errado ao buscar o histórico desse cliente!');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Função para formatar a data no formato HH:mm DD/MM/YYYY.
   * @param date Objeto Date.
   * @returns String formatada.
   */
  const formatDate = (date: Date): string => {
    return format(date, 'HH:mm dd/MM/yyyy');
  };

  /**
   * Função para resolver o valor a ser exibido com base na chave e no valor.
   * @param key Chave da mudança.
   * @param value Valor da mudança.
   * @param history Objeto de histórico atual.
   * @returns JSX.Element ou string com o valor a ser exibido.
   */
  const resolveDisplayValue = (key: string, value: any, history: SmartUpdate) => {
    // Formatação de datas
    if (dateFields.includes(key) && value) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        return formatDate(date);
      }
    }

    // Campos de referência
    const mapping = referenceFieldsMap[key];
    if (mapping && value) {
      let relatedEntity: any = history;
      for (const segment of mapping.entityPath) {
        if (relatedEntity && relatedEntity[segment]) {
          relatedEntity = relatedEntity[segment];
        } else {
          relatedEntity = null;
          break;
        }
      }

      if (relatedEntity && (relatedEntity.db_id === value || relatedEntity.id === value)) {
        return (
          <Link style={{ color: 'inherit', textDecoration: 'underline' }} to={`${mapping.route}${value}/editar`}>
            {mapping.imageField && relatedEntity[mapping.imageField] && (
              <img
                src={relatedEntity[mapping.imageField]}
                alt={relatedEntity[mapping.displayField]}
                style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 5 }}
              />
            )}
            {relatedEntity[mapping.displayField]}
          </Link>
        );
      } else {
        // Caso não encontre, exibe 'Nenhum' ou o valor original
        return 'Nenhum';
      }
    }

    // Arrays
    if (Array.isArray(value)) {
      if (value.length === 0) return 'Nenhum';
      return (
        <ul style={{ paddingLeft: '20px', margin: '5px 0' }}>
          {value.map((item, index) => (
            <li key={index}>{resolveDisplayValue(key, item, history)}</li>
          ))}
        </ul>
      );
    }

    // Objetos
    if (typeof value === 'object' && value !== null) {
      if (value.nome) {
        return value.nome;
      }
      if (value.value) {
        return value.value;
      }
      if (value.id) {
        return value.id;
      }

      return JSON.stringify(value);
    }

    // Booleanos
    if (typeof value === 'boolean') {
      return value ? 'Sim' : 'Não';
    }

    // Valor padrão
    return value || 'Nenhum';
  };

  useEffect(() => {
    fetchData(cliente_id);
  }, [cliente_id, current, pageSize]);

  const onHandleChange = (page: number) => setCurrent(page);
  const onShowSizeChange = (_: number, size: number) => setPageSize(size);

  /**
   * Função para renderizar uma mudança específica.
   * @param change Mudança a ser renderizada.
   * @param idx Índice da mudança.
   * @param history Objeto de histórico completo.
   * @returns JSX.Element representando a mudança.
   */
  const renderChange = (change: SmartChange, idx: number, history: SmartUpdate) => {
    const { key, oldValue, newValue } = change;

    // Ignorar mudanças irrelevantes
    if (key === 'edit_registro') {
      return null;
    }

    // Determinar se a chave é um campo de referência ou data
    if (dateFields.includes(key) || referenceFieldsMap[key]) {
      return (
        <div className="hour" key={`change-${idx}`}>
          <b>
            {key
              .replace('_id', '')
              .replace('_', ' ')
              .replace(/\b\w/g, (c) => c.toUpperCase())}
            :
          </b>{' '}
          De {resolveDisplayValue(key, oldValue, history)} para {resolveDisplayValue(key, newValue, history)}
        </div>
      );
    } else {
      return (
        <div className="hour" key={`change-${idx}`}>
          <b>{key}:</b> De <b>{resolveDisplayValue(key, oldValue, history)}</b> para{' '}
          <b>{resolveDisplayValue(key, newValue, history)}</b>
        </div>
      );
    }
  };

  return (
    <ActivityContents>
      <Row gutter={25}>
        <Col md={24} xs={24}>
          <Cards title="Histórico de alterações">
            {loading ? (
              <div style={{ padding: '0px 25px' }}>
                <Skeleton active /> <Skeleton active /> <Skeleton active />
              </div>
            ) : (
              <>
                <ul className="activity-list">
                  {historico.length > 0 ? (
                    historico.map((history: SmartUpdate, i) => {
                      const edited_by = history.corretor_id
                        ? history.corretor
                        : null;

                      let changedValues: Array<SmartChange> = [];
                      try {
                        const updatesObj = JSON.parse(history.updates as string);
                        changedValues = updatesObj.changed_values || [];
                      } catch (error) {
                        console.error('Erro ao parsear updates:', error);
                      }

                      return (
                        <Suspense
                          key={`update-${i}`}
                          fallback={
                            <Cards headless>
                              <Skeleton active />
                            </Cards>
                          }
                        >
                          <li className="activity-list__single">
                            <span className="activity-icon primary">
                              <FeatherIcon icon="settings" size={14} />
                            </span>
                            <div className="activity-content">
                              <div className="activity-info">
                                <img
                                  style={{ width: 40, height: 40, borderRadius: '50%' }}
                                  src={
                                    edited_by && edited_by.foto
                                      ? edited_by.foto
                                      : require('../../../../static/img/avatar/user-default.jpg')
                                  }
                                  alt={edited_by ? edited_by.nome : 'Usuário'}
                                />
                                <p>
                                  <span className="inline-text color-primary">
                                    {edited_by ? `${edited_by.nome || edited_by.email}: ` : ''}
                                    Mudança nos campos: {changedValues.map((value) => value.key).join(', ')}
                                  </span>{' '}
                                  Em {formatDate(new Date(history.last_update_time))}
                                  <div style={{ marginTop: '8px' }}>
                                    {changedValues.map((change: SmartChange, idx: number) => renderChange(change, idx, history))}
                                  </div>
                                </p>
                              </div>
                            </div>
                          </li>
                        </Suspense>
                      );
                    })
                  ) : (
                    <Empty
                      image={require('../../../../static/img/empty.svg')}
                      description="Nenhuma modificação foi contabilizada neste cliente ainda!"
                    />
                  )}
                </ul>
                <Col xs={24} className="pb-30">
                  <ProjectPagination>
                    {historico.length > 0 ? (
                      <Pagination
                        onChange={onHandleChange}
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        pageSize={pageSize}
                        current={current}
                        total={total}
                        locale={antdTableLocale.locale}
                      />
                    ) : null}
                  </ProjectPagination>
                </Col>
              </>
            )}
          </Cards>
        </Col>
      </Row>
    </ActivityContents>
  );
};

export default HistoricoPage;