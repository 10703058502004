import React, { useContext, useEffect, useState } from 'react';
import { Badge, Radio, Button, message, Spin, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import AppContext from '../../../context';
import FeatherIcon from 'feather-icons-react';
import InfiniteScroll from 'react-infinite-scroll-component';

const NotificationBox = () => {
  const context = useContext(AppContext);
  const [notificacoes, setNotificacoes] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [filter, setFilter] = useState('unread');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const filtros: any = [
        {
          field: 'notificacao.usuario_id',
          value: context.user?.db_id,
          operator: 'eq',
        },
      ];

      if (filter === 'unread') {
        filtros.push({
          field: 'notificacao.visto',
          value: false,
          operator: 'eq',
        });
      }

      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        limit: '10',
        startAt: page.toString(),
        order: JSON.stringify([{ field: 'notificacao.created_at', order: 'DESC' }]),
        filtros: JSON.stringify(filtros),
      });

      const response = await fetch(`${process.env.REACT_APP_API_URI}/notificacoes?${params.toString()}`);
      if (!response.ok) {
        throw new Error('Erro ao buscar as notificações');
      }
      const data = await response.json();
      console.log('notificacoes', data);

      // Atualizar o estado com as novas notificações
      setNotificacoes(prev => [...prev, ...data.nodes]);
      setTotal(data.total);

      // Verificar se há mais notificações para carregar
      if (notificacoes.length + data.nodes.length >= data.total) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Erro ao buscar as notificações:', error);
      message.error('Falha ao carregar notificações.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Resetar estado ao mudar o filtro
    setNotificacoes([]);
    setPage(0);
    setHasMore(true);
  }, [filter]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  const handleFilterChange = e => {
    setFilter(e.target.value);
  };

  const handleMarcarTodasComoLidas = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/notificacoes/${context.user?.db_id}/marcar-todas-como-lidas`,
        {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) {
        throw new Error('Erro ao marcar todas as notificações como lidas');
      }

      setNotificacoes(prev =>
        prev.map(notf => ({
          ...notf,
          visto: true,
        })),
      );
      setTotal(0);

      fetchData();
      message.success('Todas as notificações foram marcadas como lidas.');
    } catch (error) {
      console.error('Erro ao marcar todas as notificações como lidas:', error);
      message.error('Falha ao marcar todas as notificações como lidas.');
    } finally {
      setLoading(false);
    }
  };

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      {/* Grupo de Rádio para Filtro */}
      <div style={{ textAlign: 'center', margin: '10px 0' }}>
        <Radio.Group onChange={handleFilterChange} value={filter}>
          <Radio.Button value="all">Todas</Radio.Button>
          <Radio.Button value="unread">Não Lidas</Radio.Button>
        </Radio.Group>
      </div>

      <div
        id="scrollableDiv"
        className="atbd-top-dropdwon__nav notification-list"
        style={{ maxHeight: '800px', overflowY: 'auto' }}
      >
        <InfiniteScroll
          dataLength={notificacoes.length}
          next={() => setPage(prev => prev + 1)}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: 'center', padding: '10px 0' }}>
              <Spin />
            </div>
          }
          endMessage={
            <Divider plain style={{ textAlign: 'center' }}>
              Isto é tudo 😉
            </Divider>
          }
          scrollableTarget="scrollableDiv"
        >
          <ul className="notification-list">
            {notificacoes.map(notificacao => (
              <Notf key={notificacao.db_id} notificacao={notificacao} currentUserId={context.user?.db_id} />
            ))}
          </ul>
        </InfiniteScroll>
      </div>

      {/* Botão "Marcar Todas como Lidas" */}
      {filter === 'unread' && total > 0 && !loading && (
        <div style={{ textAlign: 'center', padding: '10px 0' }}>
          <Button type="link" onClick={handleMarcarTodasComoLidas} disabled={total === 0} loading={loading}>
            Marcar Todas como Lidas
          </Button>
        </div>
      )}
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content}>
        {total > 0 ? (
          <Badge count={total} dot offset={[-8, -5]}>
            <Link to="#" className="head-example">
              <FeatherIcon icon="bell" size={20} />
            </Link>
          </Badge>
        ) : (
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        )}
      </Popover>
    </div>
  );
};

const Notf = ({ notificacao, currentUserId }) => {
  const notificationDetails = {
    novo_imovel: {
      pre_texto: 'O imóvel ',
      pos_texto: 'foi registrado!',
      icon: 'home',
      link: `/admin/imovel/${notificacao.imovel_id}`,
      titulo: notificacao.imovel?.codigo
        ? `#${notificacao.imovel?.codigo}`
        : notificacao.imovel?.titulo,
    },
    lead_ag: {
      pre_texto: 'O lead ',
      pos_texto: 'foi passado para você!',
      icon: 'user',
      link: `/admin/cliente/${notificacao.cliente_id}/editar`,
      titulo: notificacao.cliente?.nome || notificacao.cliente?.email,
    },
    novo_cliente: {
      pre_texto: 'O cliente ',
      pos_texto: `foi cadastrado${!notificacao.enviado_por_id ? '!' : ' por um corretor.'}`,
      icon: 'user-plus',
      link: `/admin/cliente/${notificacao.cliente_id}/editar`,
      titulo: notificacao.cliente?.nome || notificacao.cliente?.email,
    },
    recadastro_cliente: {
      pre_texto: 'O cliente ',
      pos_texto: 'se cadastrou novamente no site.',
      icon: 'user-plus',
      link: `/admin/cliente/${notificacao.cliente_id}/editar`,
      titulo: notificacao.cliente?.nome || notificacao.cliente?.email,
    },
    aniversario_cliente: {
      pre_texto: 'O cliente ',
      pos_texto: 'está fazendo aniversário hoje!',
      icon: 'gift',
      link: `/admin/cliente/${notificacao.cliente_id}/editar`,
      titulo: notificacao.cliente?.nome || notificacao.cliente?.email,
    },
    aniversario_compra_imovel: {
      pre_texto: 'Hoje é o aniversário de compra do imóvel ',
      pos_texto: '!',
      icon: 'shopping-cart',
      link: `/admin/imovel/${notificacao.imovel_id}`,
      titulo: notificacao.imovel?.codigo
        ? `#${notificacao.imovel?.codigo}`
        : notificacao.imovel?.titulo,
    },
    contratacao_corretor: notificacao.enviado_por_id === currentUserId
      ? {
          pre_texto: 'Parabéns, você está fazendo aniversário de contratação 🎉!',
          pos_texto: '',
          icon: 'briefcase',
          link: '',
          titulo: '',
        }
      : {
          pre_texto: 'Hoje é o aniversário de contratação do corretor ',
          pos_texto: '!',
          icon: 'briefcase',
          link: `/admin/corretor/${notificacao.enviado_por_id}/editar`,
          titulo: notificacao.enviado_por?.nome || notificacao.enviado_por?.email || '',
        },
    aniversario_corretor: notificacao.enviado_por_id === currentUserId
      ? {
          pre_texto: 'Feliz aniversário! 🎂🎉',
          pos_texto: '',
          icon: 'gift',
          link: '',
          titulo: '',
        }
      : {
          pre_texto: 'O corretor ',
          pos_texto: 'está fazendo aniversário hoje!',
          icon: 'gift',
          link: `/admin/corretor/${notificacao.enviado_por_id}/editar`,
          titulo: notificacao.enviado_por?.nome || notificacao.enviado_por?.email || '',
        },
    checklist_vencido: {
      pre_texto: 'O checklist do cliente ',
      pos_texto: `está desatualizado. Por favor, revise. Responsável: ${notificacao.enviado_por?.nome || ''}`,
      icon: 'clipboard',
      link: `/admin/cliente/${notificacao.cliente_id}/editar`,
      titulo: notificacao.cliente?.nome || notificacao.cliente?.email,
    },
  };

  const {
    pre_texto = 'Nova notificação: ',
    pos_texto = '',
    icon = 'bell',
    link = '#',
    titulo = '',
  } = notificationDetails[notificacao.tipo] || {};

  const confirmarVisualizacao = async () => {
    if (notificacao.visto) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/notificacoes/${notificacao.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ visto: true }),
      });
      if (!response.ok) throw new Error('Erro ao marcar a notificação como vista');
    } catch (error) {
      console.error('Erro ao marcar a notificação como vista:', error);
    }
  };

  return (
    <li onMouseEnter={confirmarVisualizacao}>
      <Link to={link}>
        <div className="atbd-top-dropdwon__content notifications">
          <div
            className={`notification-icon bg-${
              ['aniversario_cliente', 'aniversario_corretor', 'checklist_vencido'].includes(notificacao.tipo)
                ? 'warning'
                : notificacao.tipo === 'novo_cliente'
                ? 'primary'
                : 'secondary'
            }`}
          >
            <FeatherIcon icon={icon} />
          </div>
          <div className="notification-content d-flex">
            <div className="notification-text">
              <Heading as="h5">
                <>
                  {pre_texto}
                  <span>{titulo}</span> {pos_texto}
                </>
              </Heading>
              <p>{new Date(notificacao.created_at).toLocaleString()}</p>
            </div>
            <div className="notification-status">{!notificacao.visto && <Badge dot />}</div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default NotificationBox;
