import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, message, DatePicker, Select } from 'antd';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import styled from 'styled-components';
import AppContext from '../../../context';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { Imóvel } from 'smart-imob-types';

const { Option } = Select;

interface RegistrarPropostaModalProps {
  visible: boolean;
  onCancel: () => void;
  db_id: string;
  onUpdateImovel?: (db_id: string, updatedImovel: any) => void;
}

const RegistrarPropostaModal = ({ db_id, visible, onCancel, onUpdateImovel }: RegistrarPropostaModalProps) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState<any>(null);
  const [etapas, setEtapas] = useState<any>([]);
  const [CEtapa, SetCEtapa] = useState<any>(null);

  const fetchEtapas = async () => {
    try {
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-etapas?${params.toString()}`);

      const data = await response.json();
      setEtapas(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEtapas();
  }, []);

  const handleChangeCliente = setCliente;

  const handleOk = async values => {
    setLoading(true);
    try {
      if (!values.descrição) {
        setLoading(false);
        return message.warning('Por favor, descreva a proposta!');
      }
      if (!values.etapa_funil_id) {
        setLoading(false);
        return message.warning('Por favor, adicione a nova etapa do cliente!');
      }
      if (!values.validade) {
        setLoading(false);
        return message.warning('Por favor, adicione a data de validade da proposta!');
      }
      const toCreateProposta = {
        edited_at: new Date(),
        edited_by: context.user?.db_id,
        descrição: values.descrição || 'Sem descrição',
        cliente_id: cliente ? cliente.value : null,
        agenciador_id: context.user?.db_id,
        created_at: new Date(),
        agenciador_nome: context.user?.nome || context.user?.email || 'Sem nome',
        validade: new Date(values.validade),
        empresa_id: context.empresa?.db_id,
        imovel_id: db_id,
        etapa_funil_id: values.etapa_funil_id,
      };

      const propostaResponse = await fetch(`${process.env.REACT_APP_API_URI}/propostas`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toCreateProposta),
      });

      if (!propostaResponse.ok) {
        const errorData = await propostaResponse.json();
        throw new Error(errorData.message || 'Erro ao registrar proposta.');
      }

      const proposta = await propostaResponse.json();

      if (onUpdateImovel) {
        onUpdateImovel(db_id, {
          propostas: [proposta],
        });
      }

      message.success('Proposta registrada!');
      onCancel();
    } catch (error) {
      console.error(error);
      message.error('Erro ao registrar proposta!');
    }
    setLoading(false);
  };

  const [form] = Form.useForm();

  return (
    <Modal type={'primary'} title="Cadastro de proposta" visible={visible} footer={null} onCancel={onCancel}>
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            {
              <Form form={form} name="proposta" onFinish={handleOk}>
                <Form.Item initialValue="" label="Cliente">
                  {visible && (
                    <FiltrarClientes
                      style={{ height: '100%' }}
                      cliente_obj={cliente}
                      handleChangeCliente={handleChangeCliente}
                      empresa_id={context.empresa?.db_id}
                    />
                  )}
                </Form.Item>
                <Form.Item name="etapa_funil_id" label="Etapa Funil">
                  {
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      showSearch={true}
                      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      className="height-fix"
                      onChange={e => SetCEtapa(e === undefined ? null : e)}
                      placeholder={'Etapa do funil'}
                      value={CEtapa || undefined}
                      size="large"
                    >
                      {etapas.map(etapa => (
                        <Option value={etapa.id}>{etapa.title}</Option>
                      ))}
                    </Select>
                  }
                </Form.Item>
                <Form.Item name="descrição" initialValue="" label="Descrição da proposta">
                  <Input placeholder="Descreva os detalhes da proposta" />
                </Form.Item>
                <Form.Item name="validade" initialValue="" label="Data de validade da proposta">
                  <DatePicker
                    format="DD/MM/YYYY"
                    locale={locale}
                    placeholder="Selecione a data de validade"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Button disabled={loading} htmlType="submit" size="default" type="primary">
                  Registrar proposta!
                </Button>
              </Form>
            }
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
};

const FixStyles = styled.div`
  & input {
    height: 46px !important;
  }
`;

export default RegistrarPropostaModal;
