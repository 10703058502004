import { Form, Input, Modal, message } from 'antd';
import axios from 'axios';
import React from 'react';

export const ChangePasswordModal = ({ open, setOpen, userId }) => {
  const [form] = Form.useForm();

  const alterarSenha = async values => {
    const { newPassword, confirmationPassword } = values;

    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URI}/corretores/alterar-senha/${userId}`, {
        newPassword,
        confirmationPassword,
      });

      if (response.status !== 200) {
        throw new Error('Falha ao alterar a senha');
      }

      message.success('Senha alterada com sucesso!');
      form.resetFields();
      setOpen(false);
    } catch (error) {
      console.error(error);
      message.error('Algo deu errado ao alterar a senha!');
    }
  };

  return (
    <Modal
      title="Alterar Senha"
      visible={open}
      onOk={() => form.submit()}
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      okText="Alterar Senha"
      cancelText="Cancelar"
    >
      <Form form={form} layout="vertical" name="change_password" onFinish={alterarSenha}>
        <Form.Item
          label="Nova Senha"
          name="newPassword"
          rules={[
            { required: true, message: 'Por favor, insira a nova senha!' },
            { min: 6, message: 'A senha deve ter no mínimo 6 caracteres!' },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Digite a nova senha" />
        </Form.Item>

        <Form.Item
          label="Confirmar Nova Senha"
          name="confirmationPassword"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, confirme a nova senha!' },
            {
              min: 6,
              message: 'A senha deve ter no mínimo 6 caracteres!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('As senhas não coincidem!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirme a nova senha" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
