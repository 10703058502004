import React, { useState, useContext } from 'react';
import { Form, Input, Checkbox, message, InputNumber, DatePicker } from 'antd';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarAgenciador from '../../../components/filtros-smart/usuarios';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import styled from 'styled-components';
import AppContext from '../../../context';
import { DateFromUnsafe } from '../../../utility/util_functions';
import ButtonGroup from 'antd/lib/button/button-group';
import locale from 'antd/es/date-picker/locale/pt_BR';

import 'moment/locale/pt-br';

interface RegistrarAluguelModalProps {
  db_id: string;
  empresa_id: string;
  onCancel: () => void;
  visible: boolean;
  onUpdateImovel?: (db_id: string, updatedImovel: any) => void;
}

const RegistrarAluguelModal = ({
  db_id,
  onCancel,
  visible,
  empresa_id,
  onUpdateImovel,
}: RegistrarAluguelModalProps) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [Ag, setAg] = useState<any>(null);
  const [Comprador, setLocatario] = useState<any>(null);
  const [ExcluirImovelOnEnd, setExcluirImovelOnEnd] = useState(false);

  const [valorMes, setValorMes] = useState<number | undefined>(undefined);

  const handleChangeAg = setAg;
  const handleChangeLocatario = setLocatario;

  const handleValorMesChange = (value: string | number | undefined) => {
    // O 'InputNumber' pode passar 'undefined' se o campo estiver vazio
    if (typeof value === 'number') {
      setValorMes(value);
    } else {
      setValorMes(undefined); // ou algum valor padrão se necessário
    }
  };

  const handleOk = async values => {
    setLoading(true);
    try {
      const toUpdate = {
        edited_at: new Date(),
        edited_by_id: context.user?.db_id,
        alugado: true,
        situacao_venda: 'Alugado',
        aluguel_imovel: {
          valor_mensal: Number(valorMes) || null,
          inicio_contrado: values.inicio_contrado ? DateFromUnsafe(values.inicio_contrado) : null,
          fim_contrado: values.fim_contrado ? DateFromUnsafe(values.fim_contrado) : null,
          observação: values.observação || null,
          data: new Date(),
          id_cliente: Comprador ? Comprador.value : null,
          id_corretor: Ag ? Ag.value : null,
        },
      };

      if (values.excluir_imovel === true) toUpdate['excluido'] = true;

      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      if (!response.ok) {
        throw new Error('Não foi possível registrar o aluguel.');
      }

      const updatedImovel = await response.json();
      message.success('Aluguel registrado!');

      if (onUpdateImovel) {
        onUpdateImovel(db_id, updatedImovel);
      }

      onCancel();
    } catch (error) {
      message.error('Erro ao registrar aluguel!');
      console.error(error);
    }
    setLoading(false);
  };

  const AlugadoPelaConcorrencia = async () => {
    try {
      setLoading(true);
      const toUpdate = {
        edited_at: new Date(),
        edited_by_id: context.user?.db_id,
        alugado: true,
        situacao_venda: 'Alugado pela concorrência',
      };
      if (ExcluirImovelOnEnd === true) {
        toUpdate['excluido'] = true;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      if (!response.ok) {
        throw new Error('Não foi possível alterar a situação do aluguel.');
      }

      const updatedImovel = await response.json();

      message.success('Situação do aluguel alterada.');

      if (onUpdateImovel) {
        onUpdateImovel(db_id, updatedImovel);
      }

      onCancel();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const ProprietarioDesistiu = async () => {
    setLoading(true);
    try {
      const toUpdate = {
        edited_at: new Date(),
        edited_by_id: context.user?.db_id,
        alugado: false,
        situacao_venda: 'Proprietário desistiu do aluguel',
      };

      if (ExcluirImovelOnEnd === true) {
        toUpdate['excluido'] = true;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      if (!response.ok) {
        throw new Error('Não foi possível alterar a situação do aluguel.');
      }

      const updatedImovel = await response.json();
      message.success('Situação do aluguel alterada.');

      if (onUpdateImovel) {
        onUpdateImovel(db_id, updatedImovel);
      }

      onCancel();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const [form] = Form.useForm();

  return (
    <Modal type={'primary'} title="Cadastro de locação" visible={visible} footer={null} onCancel={onCancel}>
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            {
              //@ts-ignore
              <Form form={form} name="contact" onFinish={handleOk}>
                <Form.Item initialValue="" label="Corretor vinculado a locação">
                  {visible && (
                    <FiltrarAgenciador
                      placeholder={'Quem finalizou o atendimento?'}
                      style={{ height: '100%' }}
                      agenciador_obj={Ag}
                      handleChangeAgenciador={handleChangeAg}
                    />
                  )}
                </Form.Item>
                <Form.Item initialValue="" label="Locatário">
                  {visible && (
                    <FiltrarClientes
                      style={{ height: '100%' }}
                      cliente_obj={Comprador}
                      handleChangeCliente={handleChangeLocatario}
                      empresa_id={empresa_id}
                    />
                  )}
                </Form.Item>
                <Form.Item name="valor_mensal" initialValue="" label="Valor mensal">
                  <InputNumber
                    formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => (value ?? '').replace(/\D/g, '')}
                    onChange={handleValorMesChange}
                    placeholder="Valor mensal"
                  />
                </Form.Item>
                <Form.Item name="inicio_contrado" initialValue="" label="Inicio do contrato">
                  <DatePicker
                    format="DD/MM/YYYY"
                    locale={locale}
                    placeholder="Selecione a data do início do contrato"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item name="fim_contrado" initialValue="" label="Fim do contrato">
                  <DatePicker
                    format="DD/MM/YYYY"
                    locale={locale}
                    placeholder="Selecione a data do fim do contrato"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item name="observação" initialValue="" label="Observações">
                  <Input placeholder="Observações" />
                </Form.Item>
                <div style={{ width: '100%', marginBottom: 25 }}>
                  <Form.Item initialValue={false} valuePropName={'checked'} name="excluir_imovel">
                    <Checkbox onChange={e => setExcluirImovelOnEnd(e.target.checked)}>
                      Excluir o imóvel ao finalizar o registro de aluguel?
                    </Checkbox>
                  </Form.Item>
                </div>
                <Button disabled={loading} htmlType="submit" size="default" type="primary">
                  Registrar locação!
                </Button>
                <div>
                  <ButtonGroup style={{ marginTop: 8 }}>
                    <Button disabled={loading} onClick={AlugadoPelaConcorrencia} size="default" type="dashed">
                      Alugado pela concorrência
                    </Button>
                    <Button disabled={loading} onClick={ProprietarioDesistiu} size="default" type="dashed">
                      Proprietário desistiu
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            }
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
};

const FixStyles = styled.div`
  & input {
    height: 46px !important;
  }
`;

export default RegistrarAluguelModal;
