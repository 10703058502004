import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Equipes = lazy(() => import('../../container/equipes/list'));
const RegrasDeDistribuicao = lazy(() => import('../../container/regras-de-distribuicao/index'));

const EquipesRoutes = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route exact path={path} component={Equipes} />
      <Route exact path={`${path}/distribuicao-de-leads`} component={RegrasDeDistribuicao} />
    </Switch>
  );
};

export default EquipesRoutes;
