import React, { Suspense, useEffect, useState } from 'react';
import { notification, Spin, Progress, message } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import DashVendas from './dashvendas';
import DashLocacao from './dashlocacao';
import DashCorretor from './dashcorretor';
import DashTemporada from './dashboard-temporada';
import ListaDeImoveis from './lista-de-imoveis';
import ListaDeClientes from './lista-de-clientes';
import HistoricoAtividades from './historico-atividades';
import Reservas from './reservas';
import Configs from './configs';
import Equipe from './equipe';
import Equipes from './equipes';
import Tarefas from './tarefas';
import Novidades from './novidades';
import Tutoriais from './tutoriais';
import Empreendimentos from './empreendimentos';
import Arquitetos from './arquitetos';
import Empreendimento from './empreendimento';
import Construtoras from './construtoras';
import Imovel from './imovel';
import Cliente from './cliente';
import PipelineClientes from './pipeline-clientes';
import Visitas from './visitas';
import Campanhas from './campanhas';
import Propostas from './propostas';
import Roleta from './roleta-de-leads';
import GerenciarArquiteto from './gerenciar-arquiteto';
import Email from './email';
import Unidades from './unidades';
import GerenciarImovel from './gerenciar-imovel';
import GerenciarEmpreendimento from './gerenciar-empreendimento';
import GerenciarConstrutora from './gerenciar-construtora';
import FeatherIcon from 'feather-icons-react';
import withAdminLayout from '../../layout/withAdminLayout';
import firebase from 'firebase/app';
import Portais from './portais';
import Blog from './blogs';
import { test_idb } from '../../indexeddb_tests';
import Error from '../../container/pages/404';
import Relatorios from './relatorios';
import Agenda from './agenda';
import CentralAnexos from './central-anexos';
import AppContext from '../../context';
// old
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { WorkerAction } from '../../WorkerController';
import { Corretor, Empresa } from 'smart-imob-types';
import { XisWhatPercentOfY } from '../../utility/util_functions';
import SuporteRoutes from './suporte';
import SmartCache from '../../indexeddb';
import createDoc from '../../utility/create_doc';
import { database } from '../../firebase';
import heic2any from 'heic2any';

// Ativar essa config para exibir a porcentagem de itens processados pelos workers na interface.
// ---> É NECESSÁRIO ATIVAR OS HEADERS "COOP" E "COEP" NA HOSPEDAGEM DO FIREBASE <---
// Ref.: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer#security_requirements
// isCrossOriginIsolated = window.location.href.includes('localhost') || crossOriginIsolated
// @ts-ignore
const isCrossOriginIsolated = false;

if (isCrossOriginIsolated) {
  // SharedArrayBuffer utilizados para comunicar a quantidade de itens processados pelos workers
  window.sab_empreendimentos = new SharedArrayBuffer(8);
  window.sab_clientes = new SharedArrayBuffer(8);
  window.sab_imoveis = new SharedArrayBuffer(8);
  window.sab_visitas = new SharedArrayBuffer(8);
}
declare global {
  interface Window {
    sab_empreendimentos: SharedArrayBuffer;
    sab_clientes: SharedArrayBuffer;
    sab_imoveis: SharedArrayBuffer;
    sab_visitas: SharedArrayBuffer;
    WorkerAction: Function;
    test_idb: Function;
    ListaClienteTimeoutTxt: null | number;
    ListaImoveisTimeoutTxt: null | number;
  }
}

if (window.location.href.includes('debug')) {
  try {
    console.log = (f =>
      function log() {
        // @ts-ignore
        var ret = f.apply(this, arguments);
        let b = document.createElement('div');
        b.innerText = JSON.stringify(arguments);
        document.body.append(b);
        return ret;
      })(console.log);
  } catch (error) {}
}
// @ts-ignore expor a indexdb pra debuggar mais facilmente
window.SmartCache = SmartCache;
window.test_idb = test_idb;
window.WorkerAction = WorkerAction;

const Admin = React.memo(
  props => {
    const { path } = useRouteMatch();

    return (
      <Switch>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Route path={path} component={Dashboard} />
          <Route path={`${path}/dash-vendas`} component={DashVendas} />
          <Route path={`${path}/dash-locacao`} component={DashLocacao} />
          <Route path={`${path}/dash-corretor`} component={DashCorretor} />
          <Route path={`${path}/dash-temporada`} component={DashTemporada} />
          <Route path={`${path}/lista-de-imoveis`} component={ListaDeImoveis} />
          <Route
            path={`${path}/incluir-imovel`}
            component={(props: any) => <GerenciarImovel {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-imovel/:db_id`}
            component={(props: any) => (
              <GerenciarImovel {...props} imovel_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route
            path={`${path}/incluir-arquiteto`}
            component={(props: any) => <GerenciarArquiteto {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-arquiteto/:db_id`}
            component={(props: any) => (
              <GerenciarArquiteto {...props} arquiteto_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route
            path={`${path}/incluir-empreendimento`}
            component={(props: any) => <GerenciarEmpreendimento {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-empreendimento/:db_id`}
            component={(props: any) => (
              <GerenciarEmpreendimento {...props} empreendimento_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route
            path={`${path}/incluir-construtora`}
            component={(props: any) => <GerenciarConstrutora {...props} inclusao={true} />}
          />
          <Route
            path={`${path}/editar-construtora/:db_id`}
            component={(props: any) => (
              <GerenciarConstrutora {...props} construtora_id={props.match.params.db_id} edicao={true} />
            )}
          />
          <Route path={`${path}/lista-de-clientes`} component={ListaDeClientes} />
          <Route path={`${path}/pipeline-de-clientes`} component={PipelineClientes} />
          <Route exact path={`${path}/pipeline/:id`} component={PipelineClientes} />
          <Route exact path={`${path}/funil/:tipo`} component={PipelineClientes} />

          <Route path={`${path}/reservas`} component={Reservas} />
          <Route
            path={`${path}/incluir-cliente`}
            component={(props: any) => <ListaDeClientes {...props} startAdding />}
          />
          <Route path={`${path}/historico-de-atividades`} component={HistoricoAtividades} />
          <Route path={`${path}/equipe`} component={Equipe} />
          <Route path={`${path}/equipes`} component={Equipes} />
          <Route path={`${path}/cliente`} component={Cliente} />
          <Route path={`${path}/imovel`} component={Imovel} />
          <Route path={`${path}/novidades`} component={Novidades} />
          <Route path={`${path}/tutoriais`} component={Tutoriais} />
          <Route path={`${path}/configuracoes`} component={Configs} />
          <Route path={`${path}/empreendimento`} component={Empreendimento} />
          <Route path={`${path}/empreendimentos`} component={Empreendimentos} />
          <Route path={`${path}/arquitetos`} component={Arquitetos} />
          <Route path={`${path}/construtoras`} component={Construtoras} />
          <Route path={`${path}/email-marketing`} component={Email} />
          <Route path={`${path}/portais`} component={Portais} />
          <Route path={`${path}/blogs`} component={Blog} />
          <Route path={`${path}/visitas`} component={Visitas} />
          <Route path={`${path}/campanhas`} component={Campanhas} />
          <Route path={`${path}/propostas`} component={Propostas} />
          <Route path={`${path}/tarefas`} component={Tarefas} />
          <Route path={`${path}/roleta-de-leads`} component={Roleta} />
          <Route path={`${path}/relatorios`} component={Relatorios} />
          <Route path={`${path}/unidades`} component={Unidades} />
          <Route path={`${path}/suporte`} component={SuporteRoutes} />
          <Route path={`${path}/agenda`} component={Agenda} />
          <Route path={`${path}/central_anexos`} component={CentralAnexos} />
        </Suspense>
      </Switch>
    );
  },
  () => true,
);

async function registerForPushNotifications(userId: string) {
  try {
    if (Capacitor.isNativePlatform()) {
      const permissionStatus = await requestPushPermissions();
      if (!permissionStatus) {
        console.log('Notificação cancelada: permissão não concedida.');
        return;
      }

      await PushNotifications.register();
      setupPushNotificationListeners(userId);
    } else {
      const permissionGranted = await requestWebNotificationPermission();
      if (!permissionGranted) {
        console.log('Notificação web cancelada: permissão não concedida.');
        return;
      }

      console.log('Notificações web prontas para serem recebidas!');
    }
  } catch (error) {
    console.error('Erro ao registrar para notificações push', error);
  }
}

async function requestPushPermissions() {
  let permStatus = await PushNotifications.checkPermissions();
  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }
  return permStatus.receive === 'granted';
}

function setupPushNotificationListeners(userId: string) {
  PushNotifications.addListener('registration', async token => {
    try {
      await fetch(`${process.env.REACT_APP_API_URI}/corretores/${userId}`, {
        method: 'PATCH',
        body: JSON.stringify({ push_token: token.value }),
      });
    } catch (error) {
      console.error('Erro ao enviar token de push', error);
    }
  });

  PushNotifications.addListener('registrationError', error => console.error('Erro de registro de push', error));

  PushNotifications.addListener('pushNotificationReceived', notification =>
    console.log('Notificação push recebida', notification),
  );

  PushNotifications.addListener('pushNotificationActionPerformed', notification =>
    console.log('Ação realizada a partir de uma notificação push', notification),
  );
}

async function requestWebNotificationPermission() {
  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    console.error('Permissão para notificações foi negada.');
    return false;
  }
  return true;
}

function sendWebNotification(title, options) {
  if (!('Notification' in window)) {
    console.error('Este navegador não suporta notificações desktop');
    return;
  }

  if (Notification.permission === 'granted') {
    new Notification(title, options);
  } else if (Notification.permission !== 'denied') {
    requestWebNotificationPermission().then(permissionGranted => {
      if (permissionGranted) {
        new Notification(title, options);
      }
    });
  }
}

/**
 * Estiliza a página e adiciona os componentes da volta (Menu lateral e topo)
 */
const AdminThemed = withAdminLayout((props: any) => <Admin {...props} />);
// variavel usada para mostrar notificações no mobile quando o app estiver em foco
let ultima_not_id: boolean | string = false;

function WrapWithAppContext() {
  const [empresa, setEmpresa] = useState<Empresa | any>(null);
  const [infoEmpresa, setInfoEmpresa] = useState<any>(null);
  const [user, setUser] = useState<Corretor | any | null>(null);
  const [loadingInicial, setLoadingInicial] = useState(false);
  const [usersNaEmpresa, setUsersNaEmpresa] = useState<any[]>([]);
  const [Equipes, setEquipes] = useState<any[]>([]);
  const [Unidades, setUnidades] = useState<any[]>([]);
  const [Notificacoes, setNotificacoes] = useState<any[]>([]);
  const [fotosParaFazerUpload, setFotosParaFazerUpload] = useState<FotoQueue[]>([]);
  const [semEmpresa, setSemEmpresa] = useState(false);

  /**
   * Esse callback é chamado apenas uma vez, ele cria listeners para o usuário, a empresa, os usuarios da empresa e o documento info_empresas da empresa do usuario logado.
   * Após isso ele tenta executar todos os métodos de sincronização do banco para o indexeddb (alguns desses métodos estão acima),
   */
  useEffect(() => {
    // let user_listener: any | Function;
    // let empresa_listener: any | Function;
    let info_listener: any | Function;
    let notf_listener: any | Function;
    (async () => {
      setLoadingInicial(true);

      const currentUser = firebase.auth().currentUser as firebase.User;
      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores/${currentUser.uid}`);
      const userData = await response.json();

      if (!userData.empresa_id) {
        setLoadingInicial(false);
        return setSemEmpresa(true);
      }
      setUser(userData);
      // Esse id vai se utilizado para pegar os arquitetos e construtoras quando for possivel
      // @ts-ignore
      global.soft_store_empresa_id = userData.empresa_id;
      // @ts-ignore
      window.database = database;

      const responseEmpresa = await fetch(`${process.env.REACT_APP_API_URI}/empresas/${userData.empresa_id}`);
      const empresaData = await responseEmpresa.json();

      setEmpresa(empresaData);

      const params = new URLSearchParams({
        empresa_id: userData.empresa_id,
      });

      info_listener = database
        .doc(`info_empresas/${userData.empresa_id}`)
        .onSnapshot(snap => setInfoEmpresa(snap.data() || {}));

      notf_listener = database
        .collection(`users/${currentUser.uid}/notificacoes`)
        .orderBy('created_at', 'desc')
        .limit(5)
        .onSnapshot(snap => {
          if (snap.docs.length > 0 && ultima_not_id && ultima_not_id !== snap.docs[snap.docs.length - 1].id) {
            if (window.screen.width > 800) {
              message.info('Você recebeu uma nova notificação! Cheque sua caixa de notificações!');
            }
          }

          ultima_not_id = snap.docs[snap.docs.length - 1] ? snap.docs[snap.docs.length - 1].id : false;

          return setNotificacoes(snap.docs.map(createDoc));
        });

      const responseEquipe = await fetch(`${process.env.REACT_APP_API_URI}/equipes?${params}`);
      const equipeData = await responseEquipe.json();
      setEquipes(equipeData);

      database
        .collection(`empresas/${userData.empresa_id}/unidades`)
        .onSnapshot(snap => setUnidades(snap.docs.map(createDoc)));

      const responseCorretores = await fetch(`${process.env.REACT_APP_API_URI}/corretores?${params}`);
      const corretorData = await responseCorretores.json();
      setUsersNaEmpresa(corretorData);

      // Chama todos ao mesmo tempo

      // Toda as coisa feia a gente passa pro web worker fazer
      try {
        if (userData.empresa_id === '2GObPcOGelkJsFaqYs6B') {
          await WorkerAction(
            { fn: 'sync-imoveis', input: { sab: window.sab_imoveis, empresa_id: userData.empresa_id } },
            true,
          );
          await WorkerAction(
            { fn: 'sync-clientes', input: { sab: window.sab_imoveis, empresa_id: userData.empresa_id } },
            true,
          );
          await WorkerAction(
            { fn: 'sync-empreendimentos', input: { sab: window.sab_empreendimentos, empresa_id: userData.empresa_id } },
            true,
          );
          await WorkerAction({
            fn: 'sync-visitas',
            input: { sab: window.sab_visitas, empresa_id: userData.empresa_id },
          });
        } else {
          const awz = await Promise.all([
            WorkerAction({
              fn: 'sync-imoveis',
              config: {
                debugPerformance: true,
              },
              input: {
                sab: window.sab_imoveis,
                empresa_id: userData.empresa_id,
                // categoria: user_data.categoria
              },
            }),
            WorkerAction(
              {
                fn: 'sync-clientes',
                config: { debugPerformance: true },
                input: {
                  sab: window.sab_imoveis,
                  empresa_id: userData.empresa_id,
                  // only_from_corretor_e_proprietarios: user_data.empresa === '41qY4Jle37OgdEVsvjOi' ? user_data.db_id : undefined,
                },
              },
              true,
            ),
            WorkerAction(
              {
                fn: 'sync-empreendimentos',
                config: { debugPerformance: true },
                input: { sab: window.sab_empreendimentos, empresa_id: userData.empresa_id },
              },
              true,
            ),
            WorkerAction({
              fn: 'sync-visitas',
              config: { debugPerformance: true },
              input: { sab: window.sab_visitas, empresa_id: userData.empresa_id },
            }),
          ]);
          console.log({ awz });
        }
      } catch (error) {
        console.log(error);
      }

      setLoadingInicial(true);
      // WorkerAction({ fn:'recommender-update' })

      // https://capacitorjs.com/docs/guides/push-notifications-firebase#integrating-firebase-with-the-android-app
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      registerForPushNotifications(currentUser.uid);
    })();
    return () => {
      // user_listener();
      // empresa_listener();
      info_listener();
      notf_listener();
    };
  }, []);

  useEffect(() => {
    (async () => {
      const notificationParam = new URLSearchParams(window.location.search);
      const notification_token = notificationParam.get('notification_token');
      if (notification_token && user && user.notification_token != notification_token) {
        await fetch(`${process.env.REACT_APP_API_URI}/corretores/${user.db_id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            notification_token,
          }),
        });
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      const sessionCleaned = sessionStorage.getItem('sessionCleaned');

      if (!sessionCleaned) {
        await SmartCache.fotos_upload.deleteAll();
        sessionStorage.setItem('sessionCleaned', 'true');
      }

      const fotos_cache = await SmartCache.fotos_upload.getAll();
      if (fotos_cache && fotos_cache.length > 0) {
        setFotosParaFazerUpload(prev => [...prev, ...fotos_cache]);
      }
    })();
  }, []);

  useEffect(() => {
    if (fotosParaFazerUpload.length === 0) return;

    const processPhotos = async () => {
      const photosByImovel = fotosParaFazerUpload.reduce((acc, photo) => {
        const imovelId = photo.imovel_id;
        if (!acc[imovelId]) {
          acc[imovelId] = [];
        }
        acc[imovelId].push(photo);
        return acc;
      }, {} as { [key: string]: FotoQueue[] });

      for (const [imovelId, photos] of Object.entries(photosByImovel)) {
        try {
          const photosToUpload = photos.filter(photo => !photo.uploaded);
          const photosToUpdate = photos.filter(photo => photo.uploaded);

          const uploadedPhotos = await Promise.all(photosToUpload.map(photo => uploadPhoto(photo)));

          const updatedPhotos = await Promise.all(photosToUpdate.map(photo => updatePhoto(photo)));

          const allPhotos = [...uploadedPhotos, ...updatedPhotos];

          await sendPhotosToServer(imovelId, allPhotos);

          setFotosParaFazerUpload(prev => prev.filter(p => !photos.includes(p)));
          await SmartCache.fotos_upload.deleteMultiple(photos.map(p => p.id));

          if (photosToUpload.length > 0) {
            const codigo = photos[0].imovel || photos[0].empreendimento || imovelId;

            let message = `${uploadedPhotos.length} fotos foram vinculadas!`
            if (photos[0].imovel) {
              message = `${uploadedPhotos.length} fotos do imóvel #${codigo} foram vinculadas!`
            }
        
            if (photos[0].empreendimento) {
              message = `${uploadedPhotos.length} fotos do empreendimento #${codigo} foram vinculadas!`
            }

            notification.success({
              message,
            });
          }
        } catch (error) {
          console.error('Error processing photos for imóvel', imovelId, error);
          setFotosParaFazerUpload(prev => prev.filter(p => !photos.includes(p)));
        }
      }
    };

    processPhotos();
  }, [fotosParaFazerUpload]);

  const convertHeicToJpeg = async (base64String: string): Promise<string> => {
    const response = await fetch(base64String);
    const blob = await response.blob();

    if (blob.type === 'image/heic' || blob.type === 'image/heif') {
      try {
        const convertedBlob = await heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 0.8, // Ajuste a qualidade conforme necessário
        });
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(convertedBlob as Blob);
        });
      } catch (conversionError) {
        console.error('Erro ao converter HEIC para JPEG:', conversionError);
        throw conversionError;
      }
    }
    return base64String;
  };

  const getContentType = (base64String: string): string => {
    if (base64String.startsWith('data:image/jpeg') || base64String.startsWith('data:image/jpg')) {
      return 'image/jpeg';
    }
    if (base64String.startsWith('data:image/png')) {
      return 'image/png';
    }
    return 'image/jpeg';
  };

  const uploadPhoto = async (photo: FotoQueue) => {
    let description = `Fazendo upload da foto`
    if (photo.imovel) {
      description = `Fazendo upload da foto do imóvel #${photo.imovel}`
    }

    if (photo.empreendimento) {
      description = `Fazendo upload da foto do empreendimento #${photo.empreendimento}`
    }

    notification.open({
      key: photo.imovel_id,
      message: 'Fazendo upload de fotos!',
      description,
      icon: <FeatherIcon icon="image" />,
    });

    try {
      let miniatura = photo.miniatura;
      if (miniatura.startsWith('data:image/heic') || miniatura.startsWith('data:image/heif')) {
        miniatura = await convertHeicToJpeg(miniatura);
      }

      let resized = photo.resized;
      if (resized && (resized.startsWith('data:image/heic') || resized.startsWith('data:image/heif'))) {
        resized = await convertHeicToJpeg(resized);
      }

      const mainContentType = getContentType(miniatura);
      const resizedContentType = getContentType(resized);

      const mainRef = firebase
        .storage()
        .ref(`empresas/${photo.empresa}/${photo.imovel_id}`)
        .child(photo.id);
      const mainRefRes = await mainRef.putString(miniatura, 'data_url', {
        contentType: mainContentType,
      });
      const mainUrl = await mainRefRes.ref.getDownloadURL();

      const resizedRef = firebase
        .storage()
        .ref(`empresas/${photo.empresa}/${photo.imovel_id}/resize`)
        .child(photo.id);
      const resizedRefRes = await resizedRef.putString(resized, 'data_url', {
        contentType: resizedContentType,
      });
      const resizedUrl = await resizedRefRes.ref.getDownloadURL();

      notification.close(photo.imovel_id);
      return {
        uploaded: true,
        source: { uri: mainUrl },
        resized: resizedUrl,
        ordem: photo.ordem,
        destaque: photo.destaque,
        id: photo.id,
        empresa_id: photo.empresa_id,
        imovel_id: photo.imovel_id,
        empreendimento_id: photo.empreendimento_id,
      };
    } catch (error) {
      console.error('Error uploading photo', photo.id, error);
      notification.close(photo.imovel_id);
      throw error;
    }
  };

  const updatePhoto = async (photo: FotoQueue) => {
    try {
      const fotoAtualizada = {
        ordem: photo.ordem,
        destaque: photo.destaque,
      };

      await fetch(`${process.env.REACT_APP_API_URI}/fotos/${photo.id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fotoAtualizada),
      });

      return {
        ...fotoAtualizada,
        id: photo.id,
        empresa_id: photo.empresa_id,
        imovel_id: photo.imovel_id,
        empreendimento_id: photo.empreendimento_id,
      };
    } catch (error) {
      console.error('Error updating photo', photo.id, error);
      throw error;
    }
  };

  const sendPhotosToServer = async (imovelId: string, photos: any[]) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URI}/fotos/imovel/${imovelId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fotos: photos }),
      });
    } catch (error) {
      console.error('Error sending photos to server for imóvel', imovelId, error);
      throw error;
    }
  };

  const set_fotos_to_upload = async (fotos: FotoQueue[]) => {
    await SmartCache.fotos_upload.addMultiple(fotos);
    setFotosParaFazerUpload(prev => [...prev, ...fotos]);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        // @ts-ignore
        unidades: Unidades,
        empresa,
        // @ts-ignore
        users_empresa: usersNaEmpresa,
        // @ts-ignore
        equipes: Equipes,
        notificacoes: Notificacoes,
        info_empresa: infoEmpresa,
        setInfoEmpresa: setInfoEmpresa,
        // @ts-ignore
        fotos_to_upload: fotosParaFazerUpload,
        set_fotos_to_upload: set_fotos_to_upload,
        /**
         * @deprecated
         */
        // @ts-ignore
        cache: SmartCache,
      }}
    >
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {semEmpresa && (
          <div style={{ height: '100vh', overflow: 'hidden' }}>
            <Error
              title="Você não está vinculado a nenhuma empresa! Entre em contato com o suporte para ser vinculado a outra empresa ou faça login com outra conta."
              btnText="Trocar de conta"
              clickBtn={() => firebase.auth().signOut()}
            />
          </div>
        )}
        {semEmpresa ? null : user && empresa && infoEmpresa && loadingInicial ? (
          <AdminThemed />
        ) : (
          <div
            className="spin"
            style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', padding: '10% 0', height: '95vh' }}
          >
            <img
              style={{ position: 'absolute', width: 300 }}
              src={'https://www.icegif.com/wp-content/uploads/2023/07/icegif-1262.gif'}
            />
            <div style={{ position: 'relative' }}>
              <TextProgress />
            </div>
          </div>
        )}
      </Suspense>
    </AppContext.Provider>
  );
}

const TextProgress = React.memo(() => {
  if (!isCrossOriginIsolated) return null;

  const [Empreendimento_n_total, setEmpreendimento_n_total] = useState<null | number[]>(null);
  const [Imoveis_n_total, setImoveis_n_total] = useState<null | number[]>(null);
  const [Cliente_n_total, setCliente_n_total] = useState<null | number[]>(null);
  const [Visitas_n_total, setVisitas_n_total] = useState<null | number[]>(null);

  useEffect(() => {
    const uintarr_empreendimentos = new Uint32Array(window.sab_empreendimentos);
    const uintarr_clientes = new Uint32Array(window.sab_clientes);
    const uintarr_imoveis = new Uint32Array(window.sab_imoveis);
    const uintarr_visitas = new Uint32Array(window.sab_visitas);

    setEmpreendimento_n_total(Array.from(uintarr_empreendimentos));
    setImoveis_n_total(Array.from(uintarr_imoveis));
    setCliente_n_total(Array.from(uintarr_clientes));
    setVisitas_n_total(Array.from(uintarr_visitas));

    const interval_id = setInterval(() => {
      setEmpreendimento_n_total(Array.from(uintarr_empreendimentos));
      setImoveis_n_total(Array.from(uintarr_imoveis));
      setCliente_n_total(Array.from(uintarr_clientes));
      setVisitas_n_total(Array.from(uintarr_visitas));
    }, 100);
    return () => clearInterval(interval_id);
  }, []);

  if (!Empreendimento_n_total || !Imoveis_n_total || !Cliente_n_total || !Visitas_n_total) return null;

  const [Empreendimento_n, Empreendimento_total] = Empreendimento_n_total;
  const [Imoveis_n, Imoveis_total] = Imoveis_n_total;
  const [Cliente_n, Cliente_total] = Cliente_n_total;
  const [Visitas_n, Visitas_total] = Visitas_n_total;

  return (
    <div style={{ position: 'absolute', top: '100%' }}>
      <div>
        Imóveis: {Imoveis_total ? `(${Imoveis_n}/${Imoveis_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Imoveis_n, Imoveis_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
      <div>
        Empreendimentos: {Empreendimento_total ? `(${Empreendimento_n}/${Empreendimento_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Empreendimento_n || 0, Empreendimento_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
      <div>
        Clientes: {Cliente_total ? `(${Cliente_n}/${Cliente_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Cliente_n, Cliente_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
      <div>
        Visitas: {Visitas_total ? `(${Visitas_n}/${Visitas_total})` : ''}{' '}
        <Progress
          strokeColor={{
            '0%': '#ff8a00',
            '100%': '#FF69A5',
          }}
          percent={XisWhatPercentOfY(Visitas_n, Visitas_total || Infinity)}
          showInfo={false}
          status="active"
        />
      </div>
    </div>
  );
});

type FotoQueue = {
  isEmpreendimento?: boolean;
  db_id: string;
  empresa: string;
  miniatura: string;
  resized: string;
  ordem: number;
  destaque: boolean;
  imovel: string;
  id: string;
  imovel_id: string;
  empreendimento_id: string;
  empreendimento: string;
  empresa_id: string;
  uploaded: boolean;
};

export default WrapWithAppContext;

/**
 * @ref https://stackoverflow.com/questions/6454198/check-if-a-value-is-within-a-range-of-numbers
 * @example
 * isInRange(5, 10, 2) // true
 * isInRange(5, 5, 2) // true // 5 É inclusivo
 * isInRange(5, 0, 2) // false
 * isInRange(5, 2, 12) // true
 */
const isInRange = (x, min, max) => {
  if (min > max) {
    // array swapping
    [max, min] = [min, max];
  }
  return x >= min && x <= max;
};

/**
 * @description Remove horarios e retorna a data em getTime()
 */
const normalizarDatas = (data: Date): number => {
  let new_date = new Date(data);
  new_date.setMinutes(0);
  new_date.setHours(0);
  new_date.setSeconds(0);
  new_date.setMilliseconds(0);
  return new_date.getTime();
};

const RangeInRange = (
  rangeA: { data_inicio: Date; data_fim: Date },
  rangeB: { data_inicio: Date; data_fim: Date },
  debug_id: string,
) => {
  // Input do usuário
  const numberRangeA = {
    data_inicio: normalizarDatas(rangeA.data_inicio),
    data_fim: normalizarDatas(rangeA.data_fim),
  };

  const numberRangeB = {
    data_inicio: normalizarDatas(rangeB.data_inicio),
    data_fim: normalizarDatas(rangeB.data_fim),
  };

  let debug_info: string[] = [];
  let res;
  if (isInRange(numberRangeA.data_inicio, numberRangeB.data_inicio, numberRangeB.data_fim)) {
    debug_info.push('numberRangeA.data_inicio in numberRangeB');
    res = true;
  }
  if (isInRange(numberRangeA.data_fim, numberRangeB.data_inicio, numberRangeB.data_fim)) {
    debug_info.push('numberRangeA.data_fim in numberRangeB');
    res = true;
  }

  if (res === undefined) {
    res = false;
  }

  if (debug_id) {
    console.log(`
    --------------------
      debug_id: ${debug_id}

      numberRangeA
        - data_inicio: ${numberRangeA.data_inicio}
        - data_fim:${numberRangeA.data_fim}

      numberRangeB
        - data_inicio: ${numberRangeB.data_inicio}
        - data_fim:${numberRangeB.data_fim}

      debug_info: ${debug_info.join(' --- ')}

      return RangeInRange(numberRangeA, numberRangeB) === ${res}
    `);
  }
  return res;
};

// @ts-ignore
window.RangeInRange = RangeInRange;
