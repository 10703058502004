import React, { useState, useContext } from 'react';
import { Form, Input, Checkbox, message, InputNumber, DatePicker } from 'antd';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import styled from 'styled-components';
import AppContext from '../../../context';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { Cliente } from 'smart-imob-types';

interface RegistrarPropostaModalProps {
  visible: boolean;
  onCancel: () => void;
  db_id: string;
  onUpdateImovel?: (db_id: string, updatedImovel: any) => void;
}

const RegistrarPropostaModal: React.FC<RegistrarPropostaModalProps> = props => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [cliente, setCliente] = useState<Cliente | null>(null);

  const handleChangeCliente = setCliente;

  const handleOk = async (values: any) => {
    if (!values.data || !values.data.toDate) {
      return message.warning('Por favor, insira uma data para esta visita!');
    }
    setLoading(true);
    try {
      const toUpdate = {
        descrição: values.detalhes,
        cliente_id: cliente?.db_id,
        agenciador_id: context.user?.db_id,
        visita_online: !!values.online,
        created_at: new Date(),
        data_visita: values.data.toDate(),
        imovel_id: props.db_id,
        empresa_id: context.empresa?.db_id,
      };

      await fetch(`${process.env.REACT_APP_API_URI}/visitas`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      message.success('Visita registrada!');
      props.onCancel();
    } catch (error) {
      console.error(error);
      message.error('Erro ao registrar visita!');
    } finally {
      setLoading(false);
    }
  };

  const [form] = Form.useForm();

  return (
    <Modal type={'primary'} title="Cadastro de visita1" visible={props.visible} footer={null} onCancel={props.onCancel}>
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            <Form form={form} name="visita" onFinish={handleOk}>
              <Form.Item initialValue="" label="Cliente">
                {props.visible && (
                  <FiltrarClientes
                    style={{ height: '100%' }}
                    cliente_obj={cliente}
                    handleChangeCliente={handleChangeCliente}
                    empresa_id={context.empresa?.db_id}
                  />
                )}
              </Form.Item>
              <Form.Item name="detalhes" initialValue="" label="Detalhes da visita">
                <Input placeholder="Descreva os detalhes da visita" />
              </Form.Item>
              <Form.Item name="data" initialValue="" label="Data da visita">
                <DatePicker
                  format="DD/MM/YYYY"
                  locale={locale}
                  placeholder="Selecione a data dessa visita"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name="online" valuePropName={'checked'} initialValue={false} label="Características da visita">
                <Checkbox>Visita online</Checkbox>
              </Form.Item>
              <Button disabled={loading} htmlType="submit" size="default" type="primary">
                Registrar visita!
              </Button>
            </Form>
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
};

const FixStyles = styled.div`
  & input {
    height: 46px !important;
  }
`;

export default RegistrarPropostaModal;
